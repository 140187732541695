<div
  *ngIf="!isIE"
  class="outer {{ tradeshowType }} {{ stateService.getCurrentTopic() | async }}"
  [class.product-selected]="stateService.getCurrentProduct() | async"
>
  <bw-fixed-header></bw-fixed-header>
  <router-outlet></router-outlet>
  <bw-info-overlay></bw-info-overlay>
  <bw-screen-saver-overlay></bw-screen-saver-overlay>
  <bw-footer [tradeshowType]="tradeshowType"></bw-footer>
</div>

<div *ngIf="isIE" class="ie11">
  <div class="logo">
    <img src="{{ baseHref }}assets/images/bw_logo.svg" />
  </div>
  <span
    >Unfortunately, the browser you are using is not able to display the desired
    website the way intended.<br />Please use an alternative browser to
    optimally view our website.</span
  >
</div>

<div class="frameborder-top"></div>
<div class="frameborder-bottom"></div>
<div class="frameborder-left"></div>
<div class="frameborder-right"></div>
