import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Product } from 'src/models/product';
import { LocalizationService } from 'src/app/services/localization.service';
import EmblaCarousel from 'embla-carousel';
import { Options } from 'embla-carousel/lib/components/options';
import QRCodeGenerator from 'qrcode-svg';
import { StateService } from 'src/app/services/state.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bw-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('embla', { static: true }) protected emblaWrapper: ElementRef<any>;

  @Input() product: Product;
  @Input() lang: string;
  @Input() defaultLang: string;

  embla: EmblaCarousel;
  emblaCurrentSnapIdx = 0;
  latestTap: number;
  isFullscreen: boolean;
  qrCodeSvg: SafeHtml;

  constructor(
    public stateService: StateService,
    public i18nService: LocalizationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.generateQrCode();
    this.stateService
      .getProductInFullscreen()
      .pipe(untilDestroyed(this))
      .subscribe((fullscreen) => (this.isFullscreen = fullscreen));
  }

  ngAfterViewInit(): void {
    const options: Partial<Options> = {
      loop: false,
      speed: 5,
    };
    this.embla = EmblaCarousel(this.emblaWrapper.nativeElement, options);
    this.embla.on(
      'select',
      () => (this.emblaCurrentSnapIdx = this.embla.selectedScrollSnap())
    );
  }

  ngOnDestroy(): void {
    this.embla.destroy();
  }

  generateQrCode(): void {
    if (this.product.QrCodeUrl[this.lang]) {
      const svg = new QRCodeGenerator({
        content: this.product.QrCodeUrl[this.lang],
        padding: 0,
        width: 200,
        height: 200,
        ecl: 'L',
      })
        .svg()
        .replace('width="200" height="200"', 'viewBox="0, 0, 200, 200"');
      this.qrCodeSvg = this.sanitizer.bypassSecurityTrustHtml(svg);
    }
  }

  scrollToMedia(index: number) {
    this.embla.scrollTo(index);
  }

  checkForDoubleTap() {
    const now = new Date().getTime();
    const timeSince = now - this.latestTap;
    if (timeSince < 500 && timeSince > 0) {
      this.stateService.setProductFullscreen(!this.isFullscreen);
      this.latestTap = 0;
    } else {
      this.latestTap = new Date().getTime();
    }
  }
}
