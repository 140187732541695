import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Product } from 'src/models/product';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Settings } from 'src/models/settings';
import { APP_BASE_HREF } from '@angular/common';

require('es6-promise').polyfill();
require('isomorphic-fetch');

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private currentProduct = new BehaviorSubject<Product>(null);
  private currentTopic = new BehaviorSubject<string>('');
  private currentLanguage = new BehaviorSubject<string>('');
  private productInFullScreen = new BehaviorSubject<boolean>(false);
  private overlayOpened = new BehaviorSubject<boolean>(false);
  private infoOverlayOpened = new BehaviorSubject<boolean>(false);
  private settings = new ReplaySubject<Settings>(1);
  private availableLanguages = new ReplaySubject<string[]>(1);
  private timer: any;
  private screenSaverTimer: any;
  private screenSaverOverlayOpened = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    this.getSettingsFromJson();
    this.setTopic(this.getFirstUrlSegment());
    this.resetTimer();
  }

  //Set timestamp of last screen touch
  setLastTouchTime() {
    this.resetTimer()
    setTimeout(()=>{
      this.setScreenSaverOverlayOpened(false);
    }, 200)
  }

  private getFirstUrlSegment(): string {
    const urlWithoutSlash = location.pathname.substr(1);
    return urlWithoutSlash.indexOf('/') > -1
      ? urlWithoutSlash.substr(0, urlWithoutSlash.indexOf('/'))
      : urlWithoutSlash;
  }

  private getSettingsFromJson() {
    fetch(`${this.baseHref}assets/export.json`)
      .then((r) => r.text())
      .then((json) => {
        const settings = JSON.parse(json).settings;
        this.currentLanguage.next(settings.DefaultLanguage);
        this.availableLanguages.next(
          settings.AdditionalLanguages
            ? (
                (settings.DefaultLanguage +
                  ',' +
                  settings.AdditionalLanguages) as string
              ).split(',')
            : [settings.DefaultLanguage]
        );
        this.settings.next({
          ResetDelay: settings.ResetDelay,
          FrameBorder: settings.FrameBorder,
          EnableTopicNavigation:
            settings.EnableTopicNavigation === 'True' &&
            settings.TradeShowType === 'aftermarket', // always disbale topic navigation for OE version
          ShowCompanyPresentation: settings.ShowCompanyPresentation === 'True',
          DefaultLanguage: settings.DefaultLanguage,
          TradeShowType: settings.TradeShowType,
          ScreenSaverVideo: settings.ScreensaverVideo,
        });
      });
  }

  private handleTimer() {
    this.setCurrentProduct(null);
    this.setOverlayOpened(false);
    this.setInfoOverlayOpened(false);
    this.settings.pipe(first()).subscribe((settings) => {
      this.currentLanguage.next(settings.DefaultLanguage);
      if (settings.EnableTopicNavigation) {
        this.router.navigate(['overview']);
      }
    });
  }

  private resetTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.settings.pipe(first()).subscribe(
      (settings) =>
        (this.timer = setTimeout(() => {
          this.handleTimer();
          if (settings.ScreenSaverVideo) {
            this.setScreenSaverOverlayOpened(true);
          }
        }, settings.ResetDelay * 1000))
    );
  }

  setTopic(topic: string) {
    this.resetTimer();
    this.currentTopic.next(topic);
  }

  setLanguage(lang: string) {
    this.resetTimer();
    this.currentLanguage.next(lang);
  }

  setNextAvailableLanguage() {
    this.resetTimer();
    this.currentLanguage
      .asObservable()
      .pipe(first())
      .subscribe((l) => {
        this.availableLanguages.subscribe((availableLanguages) => {
          const currentLangIndex = availableLanguages.indexOf(l);
          this.currentLanguage.next(
            availableLanguages.length > currentLangIndex + 1
              ? availableLanguages[currentLangIndex + 1]
              : availableLanguages[0]
          );
        });
      });
  }

  setCurrentProduct(product: Product) {
    this.resetTimer();
    this.setOverlayOpened(true);
    this.currentProduct.next(product);
  }

  setProductFullscreen(fullscreen: boolean) {
    this.resetTimer();
    this.productInFullScreen.next(fullscreen);
  }

  setOverlayOpened(open: boolean) {
    this.resetTimer();
    this.overlayOpened.next(open);
  }

  setInfoOverlayOpened(open: boolean) {
    this.resetTimer();
    this.infoOverlayOpened.next(open);
  }

  setScreenSaverOverlayOpened(open: boolean) {
    this.screenSaverOverlayOpened.next(open);
    if (open) {
      document.body.style.cursor = 'none';
      this.setProductFullscreen(false);
    } else {
      document.body.style.cursor = 'auto';
    }
  }

  getProductInFullscreen(): Observable<boolean> {
    return this.productInFullScreen.asObservable();
  }

  getCurrentProduct(): Observable<Product> {
    return this.currentProduct.asObservable();
  }

  getCurrentLang(): Observable<string> {
    return this.currentLanguage.asObservable();
  }

  getCurrentTopic(): Observable<string> {
    return this.currentTopic.asObservable();
  }

  getSettings(): Observable<Settings> {
    return this.settings.asObservable();
  }

  getAvailableLanguages(): Observable<string[]> {
    return this.availableLanguages.asObservable();
  }

  getOverlayOpened(): Observable<boolean> {
    return this.overlayOpened.asObservable();
  }

  getInfoOverlayOpened(): Observable<boolean> {
    return this.infoOverlayOpened.asObservable();
  }
  getScreenSaverOverlayOpened(): Observable<boolean> {
    return this.screenSaverOverlayOpened.asObservable();
  }
}
