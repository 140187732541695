import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { switchMap } from 'rxjs/operators';
import { Product } from 'src/models/product';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { APP_BASE_HREF } from '@angular/common';
import {
  ActivatedRoute,
  Router,
  NavigationStart,
  Event as NavigationEvent,
} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'bw-fixed-header',
  templateUrl: './fixed-header.component.html',
  styleUrls: ['./fixed-header.component.scss'],
})
export class FixedHeaderComponent implements OnInit {
  currentProduct: Product;
  currentTopicText: string;
  currentLang: string;
  enableTopicNavigation: boolean;
  productInFullscreen: boolean;
  showLanguageSwitch: boolean;
  overlayOpened: boolean;
  infoOverlayOpened: boolean;
  aftermarketTopic: String;
  isOverview: boolean;

  constructor(
    public stateService: StateService,
    public i18nService: LocalizationService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(APP_BASE_HREF)
    public baseHref: string
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(({ topic }) => {
      this.aftermarketTopic = topic;
    });
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        this.isOverview = event.url === '/overview'
      }
    });
    this.stateService
      .getCurrentTopic()
      .pipe(
        untilDestroyed(this),
        switchMap((t) => this.i18nService.getString('Title-' + t))
      )
      .subscribe((t) => (this.currentTopicText = t));
    this.stateService
      .getCurrentLang()
      .pipe(
        untilDestroyed(this),
        switchMap((l) => this.i18nService.getString(l))
      )
      .subscribe((l) => (this.currentLang = l));
    this.stateService
      .getCurrentProduct()
      .pipe(untilDestroyed(this))
      .subscribe((p) => (this.currentProduct = p));
    this.stateService
      .getProductInFullscreen()
      .pipe(untilDestroyed(this))
      .subscribe((f) => (this.productInFullscreen = f));
    this.stateService
      .getSettings()
      .pipe(untilDestroyed(this))
      .subscribe((s) => (this.enableTopicNavigation = s.EnableTopicNavigation));
    this.stateService
      .getAvailableLanguages()
      .pipe(untilDestroyed(this))
      .subscribe((l) => (this.showLanguageSwitch = l.length > 1));
    this.stateService
      .getOverlayOpened()
      .pipe(untilDestroyed(this))
      .subscribe((o) => {
        this.overlayOpened = o;
      });
    this.stateService
      .getInfoOverlayOpened()
      .pipe(untilDestroyed(this))
      .subscribe((o) => {
        this.infoOverlayOpened = o;
      });
  }

  changeLang(): void {
    this.stateService.setNextAvailableLanguage();
  }

  clickBackLink(): void {
    if (this.infoOverlayOpened) {
      this.stateService.setInfoOverlayOpened(false);
    } else if (this.productInFullscreen) {
      this.stateService.setProductFullscreen(false);
    } else if (this.overlayOpened) {
      this.stateService.setOverlayOpened(false);
    } else if (this.enableTopicNavigation) {
      this.router.navigate(['overview']);
    }
  }
}
