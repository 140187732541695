import { Inject, Injectable } from '@angular/core';
import { StateService } from './state.service';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private translations = new ReplaySubject<any>(1);

  constructor(
    private stateService: StateService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    this.getDataFromJson();
  }

  private getDataFromJson() {
    fetch(`${this.baseHref}assets/export.json`)
      .then((r) => r.text())
      .then((json) => this.translations.next(JSON.parse(json).labels));
  }

  getString(key: string): Observable<string> {
    return this.translations.pipe(
      switchMap((translations) => {
        return this.stateService
          .getCurrentLang()
          .pipe(
            map((lang) =>
              translations[key] ? (translations[key][lang] as string) : ''
            )
          );
      })
    );
  }
}
