<div class="wrapper">
  <div
    class="topic"
    *ngIf="electricProducts && electricProducts.length && !isAftermarket"
  >
    <div class="topic-header large" [class.minimized]="topicHeadingMinimized">
      <div
        [innerHtml]="i18nService.getString('TopicSectionElectric') | async"
      ></div>
      <div
        [innerHtml]="
          i18nService.getString('TopicSectionElectricSubtitle') | async
        "
      ></div>
    </div>

    <div class="tile-wrapper">
      <bw-product-tile
        *ngFor="let product of electricProducts"
        [product]="product"
        (click)="selectProduct(product)"
        [lang]="lang"
      ></bw-product-tile>
    </div>
  </div>

  <div
    class="topic"
    *ngIf="
      conventionalProducts && conventionalProducts.length && !isAftermarket
    "
  >
    <div class="topic-header">
      <div
        [innerHtml]="i18nService.getString('TopicSectionConventional') | async"
      ></div>
    </div>
    <div class="tile-wrapper">
      <bw-product-tile
        *ngFor="let product of conventionalProducts"
        [product]="product"
        (click)="selectProduct(product)"
        [lang]="lang"
      ></bw-product-tile>
    </div>
  </div>

  <div
    class="topic"
    *ngIf="
      isAftermarket &&
      aftermarketProducts &&
      enableTopicNavigation &&
      aftermarketProducts.length
    "
  >
    <div class="topic-header large" [class.minimized]="topicHeadingMinimized">
      <div [innerHtml]="aftermarketTopicHeading"></div>
      <div></div>
    </div>

    <div class="tile-wrapper">
      <bw-product-tile
        *ngFor="let product of aftermarketProducts"
        [product]="product"
        (click)="selectProduct(product)"
        [lang]="lang"
      ></bw-product-tile>
    </div>
  </div>

  <div
    class="topic"
    *ngIf="
      isAftermarket && !enableTopicNavigation && products && products.length
    "
  >
    <div class="topic-header large" [class.minimized]="topicHeadingMinimized">
      <div
        [innerHtml]="i18nService.getString('AftermarketIntroTitle') | async"
      ></div>
      <div
        [innerHtml]="i18nService.getString('AftermarketIntroSubTitle') | async"
      ></div>
    </div>

    <div class="tile-wrapper">
      <bw-product-tile
        *ngFor="let product of products"
        [product]="product"
        (click)="selectProduct(product)"
        [lang]="lang"
      ></bw-product-tile>
    </div>
  </div>

  <bw-product
    *ngIf="currentProduct"
    [class.product-visible]="productVisible && overlayOpened"
    [product]="currentProduct"
    [lang]="lang"
  ></bw-product>
</div>
