import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TopicNavigationComponent } from './components/topic-navigation/topic-navigation.component';
import { TopicComponent } from './components/topic/topic.component';

const routes: Routes = [
  { path: 'overview', component: TopicNavigationComponent },
  { path: 'topic/:topic', component: TopicComponent },
  { path: '', component: TopicComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
