<div class="inner">
  <div class="embla-wrapper" [class.fullscreen]="isFullscreen">
    <div class="embla" #embla>
      <div class="slide-wrapper">
        <div
          class="media-detail"
          *ngFor="let media of product.Media; let i = index"
          (click)="checkForDoubleTap()"
        >
          <div class="media-inner" [ngSwitch]="media.Type">
            <bw-video-player
              *ngSwitchCase="'Video'"
              [media]="media"
              [play]="i === emblaCurrentSnapIdx"
              [lang]="lang"
            ></bw-video-player>
            <img *ngSwitchCase="'Image'" [src]="media.Sources[lang]" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="btn-fullscreen"
    (click)="stateService.setProductFullscreen(!isFullscreen)"
  >
    <img
      src="{{
        isFullscreen
          ? 'assets/icons/fluenticons/Dismiss.svg'
          : 'assets/icons/fluenticons/FullScreenMaximize.svg'
      }}"
    />
    <span>{{
      i18nService.getString(isFullscreen ? "CloseFullscreen" : "Fullscreen")
        | async
    }}</span>
  </div>
  <!-- <ngx-simplebar class="simplebar-media-tmb" [class.visible]="!isFullscreen"> -->
  <div
    class="thumbnailgrid"
    [class.visible]="!isFullscreen"
    [class.hide-scrollbar]="product.Media.length <= 3"
  >
    <div
      *ngFor="let media of product.Media; let i = index"
      class="media-thumbnail-wrapper {{ media.Type.toLowerCase() }}"
      (click)="scrollToMedia(i)"
    >
      <img
        [src]="
          media.Type == 'Video' ? media.Thumbnails[lang] : media.Sources[lang]
        "
        class="thumbnail"
        [ngClass]="media.Type == 'Video' ? 'video' : 'image'"
      />
      <img
        *ngIf="media.Type == 'Video'"
        src="assets/images/icons/ico-play.svg"
        class="play-btn"
      />
    </div>
  </div>
  <!-- </ngx-simplebar> -->
  <div class="overlay" [class.visible]="!isFullscreen">
    <ngx-simplebar class="scroll-wrapper">
      <div>
        <div class="heading-container">
          <h2 [innerHtml]="product.Name[lang]"></h2>
          <p [innerHtml]="product.ShortDescription[lang]"></p>
        </div>
        <div *ngIf="product.Features[lang]" class="content">
          <h3>{{ i18nService.getString("Features") | async }}</h3>
          <div [innerHtml]="product.Features[lang]"></div>
        </div>
        <div *ngIf="product.Benefits[lang]" class="content">
          <h3>{{ i18nService.getString("Benefits") | async }}</h3>
          <div [innerHtml]="product.Benefits[lang]"></div>
        </div>
        <div *ngIf="product.Description[lang]" class="content">
          <h3>{{ i18nService.getString("ProductDescription") | async }}</h3>
          <p [innerHtml]="product.Description[lang]"></p>
        </div>
        <div *ngIf="product.QrCodeUrl[lang]" class="content">
          <h3>{{ i18nService.getString("QRCodeTitle") | async }}</h3>
          <div class="qr-code-wrapper" [innerHTML]="qrCodeSvg"></div>
          <p
            [innerHTML]="i18nService.getString('QRCodeDescription') | async"
          ></p>
        </div>
      </div>
    </ngx-simplebar>
  </div>
</div>
