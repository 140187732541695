import { Component, Input, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { PlyrComponent } from 'ngx-plyr';
import { StateService } from 'src/app/services/state.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bw-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {
  currentLang: string;
  defaultLang: string;
  @ViewChild(PlyrComponent) plyr: PlyrComponent;
  @Input() media: any;
  @Input() lang: string;
  @Input()
  set play(play: boolean) {
    if (this.plyr) {
      this.togglePlayer(play);
    } else {
      this.startIfReady = play;
    }
  }

  startIfReady: boolean;
  videoSources: Plyr.Source[];

  plyrOptions: Plyr.Options = {
    controls: ['play', 'progress', 'play-large'],
    loop: { active: true },
    fullscreen: { enabled: false },
    autoplay: true,
  };

  constructor(public stateService: StateService) { }

  ngOnInit(): void {
    this.stateService
      .getCurrentLang()
      .pipe(untilDestroyed(this))
      .subscribe((lang) => {
        this.currentLang = lang;
        //Check if the video is available in the current language, if not use the default language
        this.videoSources = [{ src: this.media.Sources[this.currentLang] ? this.media.Sources[this.currentLang] : this.media.Sources[this.defaultLang] }] as Plyr.Source[];
      });
    //Get default language
    this.stateService
      .getSettings()
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        this.defaultLang = settings.DefaultLanguage;
      });
  }

  ngAfterViewInit(): void {
    this.togglePlayer(this.startIfReady);
  }

  togglePlayer(play: boolean) {
    if (play) {
      this.plyr.player.play();
    } else {
      this.plyr.player.stop();
    }
  }

}
