import { Inject, Injectable } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Product } from 'src/models/product';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private products = new ReplaySubject<Product[]>(1);

  constructor(@Inject(APP_BASE_HREF) private baseHref: string) {
    this.getProductsFromJson();
  }

  private getProductsFromJson() {
    fetch(`${this.baseHref}assets/export.json`)
      .then((r) => r.text())
      .then((json) => this.products.next(JSON.parse(json).products));
  }

  getTopics(): Observable<string[]> {
    return this.products
      .asObservable()
      .pipe(
        map((products) =>
          products
            .map((product) => product.Topic.toLowerCase())
            .filter((v, i, a) => a.indexOf(v) === i)
        )
      );
  }

  getProductsByTopic(topic: string): Observable<Product[]> {
    return this.products
      .asObservable()
      .pipe(
        map((products) =>
          products.filter((p) => topic === 'all' || p.Topic === topic)
        )
      );
  }

  getProducts(): Observable<Product[]> {
    return this.products.asObservable();
  }
}
