import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { Product } from 'src/models/product';
import { ProductsService } from 'src/app/services/products.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { Settings } from 'src/models/settings';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'bw-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss'],
})
export class TopicComponent implements OnInit {
  topic: string;
  products: Product[];
  aftermarketProducts: Product[];
  electricProducts: Product[];
  conventionalProducts: Product[];
  currentProduct: Product;
  productVisible: boolean;
  lang: string;
  showTopicIcon = false;
  titleLong: string;
  subTitle: string;
  overlayOpened: boolean;
  topicHeadingMinimized: boolean = false;
  isAftermarket: boolean;
  aftermarketTopicHeading: String;
  enableTopicNavigation: Boolean;

  constructor(
    private stateService: StateService,
    public i18nService: LocalizationService,
    private productService: ProductsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.productService
      .getProducts()
      .pipe(untilDestroyed(this))
      .subscribe((products) => {
        this.electricProducts = products.filter(
          ({ Topic }) => Topic === 'electric'
        );
        this.conventionalProducts = products.filter(
          ({ Topic }) => Topic !== 'electric'
        );

        this.products = products;
      });
    this.stateService
      .getCurrentLang()
      .pipe(untilDestroyed(this))
      .subscribe((lang) => (this.lang = lang));
    this.stateService
      .getCurrentProduct()
      .pipe(untilDestroyed(this))
      .subscribe((p) => {
        if (!!p) {
          this.currentProduct = p;
          setTimeout(() => {
            this.productVisible = true;
          }, 1);
        } else {
          this.productVisible = false;
          setTimeout(() => {
            this.currentProduct = p;
          }, 100);
        }
      });
    this.stateService
      .getSettings()
      .pipe(first())
      .subscribe((settings) => {
        this.isAftermarket = settings.TradeShowType === 'aftermarket';
        this.showTopicIcon = this.isAftermarket;
        this.enableTopicNavigation = settings.EnableTopicNavigation;

        if (this.enableTopicNavigation && this.router.url === '/') {
          this.router.navigate(['overview']);
        }

        if (!settings.EnableTopicNavigation && this.isAftermarket) {
          this.i18nService
            .getString('AftermarketIntroTitle')
            .pipe(untilDestroyed(this))
            .subscribe((titleLong) => (this.titleLong = titleLong));
          this.i18nService
            .getString('AftermarketIntroSubTitle')
            .pipe(untilDestroyed(this))
            .subscribe((subTitle) => (this.subTitle = subTitle));
        } else {
          this.i18nService
            .getString('TitleLong-' + this.topic)
            .pipe(untilDestroyed(this))
            .subscribe((titleLong) => (this.titleLong = titleLong));
          this.i18nService
            .getString('SubTitle-' + this.topic)
            .pipe(untilDestroyed(this))
            .subscribe((subTitle) => (this.subTitle = subTitle));
        }
      });
    this.stateService
      .getOverlayOpened()
      .pipe(untilDestroyed(this))
      .subscribe((open) => {
        setTimeout(() => {
          this.overlayOpened = open;
        }, 1);
        if (!open) {
          setTimeout(() => {
            this.currentProduct = null;
          }, 300);
        }
      });

    this.route.params.subscribe(({ topic }) => {
      this.productService
        .getProducts()
        .pipe(untilDestroyed(this))
        .subscribe((products) => {
          this.aftermarketProducts = products.filter(
            ({ Topic }) => Topic === topic
          );
        });

      this.i18nService
        .getString(`TopicOverviewTileTitle-${topic}`)
        .pipe(untilDestroyed(this))
        .subscribe((topicHeading) => {
          this.aftermarketTopicHeading = topicHeading;
        });
    });

    window.addEventListener('scroll', () => this.onScroll());
    this.onScroll();
  }

  onScroll() {
    this.topicHeadingMinimized = window.scrollY > 30;
  }

  selectProduct(product: Product) {
    this.stateService.setCurrentProduct(product);
  }
}
