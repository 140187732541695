<div>
  <div (click)="toggleOverlay()" *ngIf="showCompanyPresentation" class="info-btn">
    <img class="info-btn--icon" src="assets/icons/fluenticons/Info.svg">
    <span>{{ i18nService.getString('InfoButtonText') | async }}</span>
  </div>
</div>

<div class="logo" (click)="clickLogo()">
  <img src="{{ baseHref }}assets/images/bw_logo.svg">
</div>
