import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { zip } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'bw-topic-navigation',
  templateUrl: './topic-navigation.component.html',
  styleUrls: ['./topic-navigation.component.scss']
})
export class TopicNavigationComponent implements OnInit {

  topics: string[];
  isAftermarket: boolean;
  topicOrder = ['combustion', 'hybrid', 'electric', 'awd', 'bt', 'egm', 'it', 'tm', 'tt', 'reman'];
  topicHeadingMinimized: boolean = false;

  constructor(
    private stateService: StateService,
    private productService: ProductsService,
    public i18nService: LocalizationService,
    private router: Router) { }

    ngOnInit(): void {
      this.stateService.getSettings().pipe(first()).subscribe(settings => {
        this.isAftermarket = settings.TradeShowType === 'aftermarket';
        this.productService.getTopics().pipe(first()).subscribe(topics => {
          this.topics = this.sortTopics(topics);
          if (!settings.EnableTopicNavigation && this.isAftermarket) {
            this.selectTopic('all');
          }
          else if (!settings.EnableTopicNavigation && topics.length === 1) {
            this.selectTopic(topics[0]);
          }
        });
      });

      window.addEventListener('scroll', () => this.onScroll());
      this.onScroll();
    }

    sortTopics(topics: string[]): string[]{
      return this.topicOrder.filter(v => topics.includes(v));
    }

    selectTopic(topic: string) {
      this.stateService.setTopic(topic);
      this.router.navigate(['topic',topic]);
    }

    onScroll() {
      this.topicHeadingMinimized = window.scrollY > 30;
    }
}
