import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { first } from 'rxjs/operators';
import { APP_BASE_HREF } from '@angular/common';
@UntilDestroy()
@Component({
  selector: 'bw-info-overlay',
  templateUrl: './info-overlay.component.html',
  styleUrls: ['./info-overlay.component.scss'],
})
export class InfoOverlayComponent implements OnInit {
  infoOverlayOpened = false;
  currentLang: string;
  tradeshowType: string;
  pdfUrl: string;

  constructor(
    public stateService: StateService,
    public i18nService: LocalizationService,
    @Inject(APP_BASE_HREF)
    public baseHref: string
  ) {}

  ngOnInit(): void {
    this.stateService
      .getInfoOverlayOpened()
      .pipe(untilDestroyed(this))
      .subscribe((opened) => {
        this.infoOverlayOpened = opened;
      });
    this.stateService
      .getCurrentLang()
      .pipe(untilDestroyed(this))
      .subscribe((lang) => {
        this.currentLang = lang;
        this.pdfUrl = `${this.baseHref}assets/documents/${
          this.tradeshowType === 'aftermarket' ? 'aftermarket' : 'company'
        }-information-${this.currentLang}.pdf`;
      });
    this.stateService
      .getSettings()
      .pipe(first())
      .subscribe((settings) => {
        this.tradeshowType = settings.TradeShowType;
        this.pdfUrl = `${this.baseHref}assets/documents/${
          this.tradeshowType === 'aftermarket' ? 'aftermarket' : 'company'
        }-information-${this.currentLang}.pdf`;
      });
  }
}
