import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopicNavigationComponent } from './components/topic-navigation/topic-navigation.component';
import { TopicComponent } from './components/topic/topic.component';
import { ProductTileComponent } from './components/product-tile/product-tile.component';
import { CommonModule } from '@angular/common';
import { APP_BASE_HREF } from '@angular/common';
import { ProductComponent } from './components/product/product.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { FixedHeaderComponent } from './components/fixed-header/fixed-header.component';
import { PlyrModule } from 'ngx-plyr';
import { TopicTileComponent } from './components/topic-tile/topic-tile.component';
import { InfoOverlayComponent } from './components/info-overlay/info-overlay.component';
import { ScreenSaverOverlayComponent } from './components/screen-saver-overlay/screen-saver-overlay.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    TopicNavigationComponent,
    TopicComponent,
    ProductTileComponent,
    ProductComponent,
    VideoPlayerComponent,
    FixedHeaderComponent,
    TopicTileComponent,
    InfoOverlayComponent,
    ScreenSaverOverlayComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    SimplebarAngularModule,
    BrowserAnimationsModule,
    PlyrModule,
    PdfViewerModule
  ],
  providers: [{
    provide: APP_BASE_HREF,
    useValue: window['base-href']
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
