<a class="back-btn" [class.visible]="overlayOpened || infoOverlayOpened || (enableTopicNavigation && !isOverview)" (click)="clickBackLink()">
  <img class="back-btn--icon" src="assets/icons/fluenticons/ArrowLeft-green.svg">
  {{ i18nService.getString('Back') | async }}
</a>

<button *ngIf="showLanguageSwitch" class="language-switcher" (click)="changeLang()" aria-label="">
  <img class="language-switcher--icon-globe" src="assets/icons/fluenticons/Globe.svg">
  {{ currentLang }}
  <img class="language-switcher--icon-chevron" src="assets/icons/fluenticons/ChevronRight.svg">
</button>
