import { Component, Inject } from '@angular/core';
import { StateService } from './services/state.service';
import { first } from 'rxjs/operators';
import { APP_BASE_HREF } from '@angular/common';

@Component({
  selector: 'bw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'BorgWarner Trade Show';
  isIE: boolean;
  tradeshowType: string;

  constructor(
    public stateService: StateService,
    @Inject(APP_BASE_HREF) public baseHref: string
  ) {
    this.injectCssVariables();
    this.setupPdfWorker();
    this.addInteractionListener();
  }

  addInteractionListener() {
    window.addEventListener('pointerdown', () => {
      this.stateService.setLastTouchTime();
    }, true)
    window.addEventListener('scroll', () => {
      this.stateService.setLastTouchTime();
    }, true)
    window.addEventListener('mousemove', () => {
      this.stateService.setLastTouchTime();
    }, true)
    window.addEventListener('touchstart', () => {
      this.stateService.setLastTouchTime();
    }, true)
    window.addEventListener('touchmove', () => {
      this.stateService.setLastTouchTime();
    }, true)
  }

  injectCssVariables() {
    this.isIE = /*@cc_on!@*/ false || !!(document as any).documentMode;
    if (!this.isIE) {
      this.stateService
        .getSettings()
        .pipe(first())
        .subscribe((settings) => {
          const cssVariables = `
            html {
              --frameBorderSize: ${settings.FrameBorder}px;
              --baseHref: ${this.baseHref}
            }`;
          this.injectStyles(cssVariables);
          this.tradeshowType = settings.TradeShowType;
        });
    }
  }

  injectStyles(css: string) {
    const style = document.createElement('style');
    document.head.append(style);
    style.innerHTML = css;
  }

  setupPdfWorker() {
    (<any>(
      window
    )).pdfWorkerSrc = `${this.baseHref}assets/pdfjs-dist/pdf.worker.js`;
  }
}
