import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';
import { APP_BASE_HREF } from '@angular/common';
import { LocalizationService } from 'src/app/services/localization.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bw-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() tradeshowType: string;

  infoOverlayOpened: boolean;
  showCompanyPresentation: boolean;

  constructor(
    public stateService: StateService,
    private router: Router,
    public i18nService: LocalizationService,
    @Inject(APP_BASE_HREF)
    public baseHref: string
  ) {}

  ngOnInit(): void {
    this.stateService
      .getInfoOverlayOpened()
      .pipe(untilDestroyed(this))
      .subscribe((o) => {
        this.infoOverlayOpened = o;
      });
    this.stateService
      .getSettings()
      .pipe(untilDestroyed(this))
      .subscribe(
        (s) => (this.showCompanyPresentation = s.ShowCompanyPresentation)
      );
  }

  clickLogo(): void {
    this.stateService.setProductFullscreen(false);
    this.stateService.setOverlayOpened(false);
    this.stateService.setInfoOverlayOpened(false);
  }

  toggleOverlay(): void {
    this.stateService.setInfoOverlayOpened(!this.infoOverlayOpened);
  }
}
