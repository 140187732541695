import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { StateService } from 'src/app/services/state.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
@UntilDestroy()
@Component({
  selector: 'bw-topic-tile',
  templateUrl: './topic-tile.component.html',
  styleUrls: ['./topic-tile.component.scss']
})
export class TopicTileComponent {
  @Input() topic: string;
  @Input() title: string;
  topicImages: any;
  currentTopicImage: string;
  currentLang: string;
  @Output() topicSelected = new EventEmitter<string>();

  constructor(private productService: ProductsService, private stateService: StateService,) {
  }
  ngOnInit(): void {
    this.productService.getProductsByTopic(this.topic).pipe(first()).subscribe(product => { this.topicImages = product[0].TopicImages });

    this.stateService
      .getCurrentLang()
      .pipe(untilDestroyed(this))
      .subscribe((lang) => {
        this.currentLang = lang;

        this.currentTopicImage = this.topicImages[this.currentLang];
      });
  }

  selectTopic() {
    this.topicSelected.next(this.topic);
  }
}
